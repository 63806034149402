import React, { useRef, useEffect, useState } from 'react';
import logo from '../assets/jzlogo.png';
import facebookicon from '../assets/f_logo_RGB-White_1024 1.svg';
import twittericon from '../assets/twitter-512 1.svg';
import instagramicon from '../assets/new-instagram-logo-white-border-icon-png-large 1.svg';
import tiktokIcon from '../assets/tiktok-512 1.svg';
import AudioPlayer from '../components/audioPlayer';
import { Link } from 'react-router-dom';



const Navbar = (props) => {

  const [width, setWidth] = useState(window.innerWidth);


  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);


  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const threshold = 0; // Set your threshold here
      if (window.pageYOffset > threshold) {
        setHasScrolled(true);

      } else {
        setHasScrolled(false);

      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <nav className={
        width > 1030 ? (hasScrolled ? 'nav-bar scrolled' : 'nav-bar') : 'nav-bar'
      }>

        <div className='nav-left'>
          <img className='vivify fadeIn duration-300' src={logo} alt='logo' />
          <ul>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/meet-the-team'>Meet The Team</Link>
            </li>
            <li>
              <Link to='/on-air'>On-Air Personalities</Link>
            </li>
            {/*  <li>
              <Link to='/itsanewday'>It's a New Day with Rip Daniels</Link>
            </li> */}
          </ul>
        </div>
        <div className='nav-right'>
          {
            width > 1030 ? <AudioPlayer url="https://media.streambrothers.com:8402/stream" /> : null
          }
          <div className='social-icons'>
            <a href='https://www.facebook.com/JZ94.5FM' target='_blank' rel='noopener noreferrer'><img src={facebookicon} alt='facebook' /></a>
            <a href='https://twitter.com/jz945?lang=en' target='_blank' rel='noopener noreferrer'><img src={twittericon} alt='twitter' /></a>
            <a href='http://instagram.com/jz945' target='_blank' rel='noopener noreferrer'><img src={instagramicon} alt='instagram' /></a>
            <a href='https://www.tiktok.com/@jz945fm?_t=8eGKjdAfL8o&_r=1' target='_blank' rel='noopener noreferrer'><img src={tiktokIcon} alt='tiktok' /></a>

          </div>

        </div>

        <div className='refresh-container'>
          <button onClick={
            () => {
              window.location.reload();
            }
          } className='refresh-icon'>
            <i className="material-icons">refresh</i>
          </button>
        </div>



      </nav>
      <style>
        {
          (props.inverted) ?
            `
            .nav-left > ul {color
            :black}

            .nav-left > img {
              filter: invert(1);
            }

            .social-icons {
              filter: invert(1);
            }

            `
            :
            null
        }

        {
          hasScrolled ?
            `
          .nav-left > ul {color
          :black}

          .nav-left > img {
            filter: invert(1);
          }

          .social-icons {
            filter: invert(1);
          }

          `
            :
            null
        }
      </style>



    </>
  );

}

export default Navbar;