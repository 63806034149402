import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'

const server = process.env.REACT_APP_DOMAIN
export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}getUser`, {
                method:'GET',
                credentials: 'include',
            })
            if(response.status != 500){
                const data = await response.json()
                console.log(data)
                thunkApi.dispatch(userInformation(data))
                thunkApi.dispatch(isLogin())
            }
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}logout`, {
                method:'POST',
                credentials: 'include',
            })
            console.log(response)
            if(response.status != 500){
                thunkApi.dispatch(resetAuth())
            }
        } catch (error) {
            console.log(error)
        }
    }
  )