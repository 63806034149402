import React, {useRef, useEffect, useState} from 'react';
import logo from '../assets/logo.svg';
import Navbar from '../landingPage/navbar';
import CampaignForm from './form';
import Fundraising from '../assets/fundraising.mp4';
import backstencil from '../assets/backstencil.png';


const NewCampaign = () => {

 useEffect(() => {
    document.querySelector("meta[name=theme-color]").setAttribute("content", "#044EB3");
    }, []);

  return (
    <>


  
        <Navbar />
        <div style={
            {
                backgroundImage: `url(${backstencil})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }
        } className='new-campaign'>

       

            <div className='new-campaign-container'>
            <div className='camp-text'>
                    <h1>Your <span>Campaign</span>  Starts Here</h1>
                    <p>Take the next step secure funding for your campaign.</p>
                </div>
            <CampaignForm />
            </div>
     

     

        
        </div>
  
    <style>
    {`
    nav {
        background-color: var(--main-color);
    }
    `}
    </style>
    </>
  );

}

export default NewCampaign;