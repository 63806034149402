import React, { useEffect, useState } from 'react';
import { ArticleCard } from '../components/articleCard';
import { Carousel } from '@mantine/carousel';
import { Palette } from 'react-palette';
import he from 'he';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';


function Article() {
    const [article, setArticle] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const location = useLocation();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }



    useEffect(() => {

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    // Function to get the id from the url
    function getIdFromUrl() {
        return location.pathname.split("/").pop();
    }

    useEffect(() => {
        const id = getIdFromUrl();

        fetch(`https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/posts/${id}`)
            .then((response) => response.json())
            .then((articleData) => {
                fetch(`https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/media/${articleData.featured_media}`)
                    .then((response) => response.json())
                    .then((mediaData) => {
                        setArticle({
                            ...articleData,
                            featured_media_src_url: mediaData.source_url,
                        });
                    });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [location]);

    // Function to truncate the excerpt
    function truncateExcerpt(excerpt, maxLength) {
        if (excerpt.length > maxLength) {
            return excerpt.slice(0, maxLength) + '...';
        }
        return excerpt;
    }


    return (
        <>


            {article && (
                <>
                    <Helmet>
                        <title>{he.decode(article.title.rendered)} | JZ 94.5 </title>
                        <meta name="description" content={article.excerpt.rendered} />
                        <meta name="keywords" content="JZ 94.5, WJZD, JZ94.5, JZ 94.5 FM, JZ 94.5 Biloxi, JZ 94.5 Mississippi, JZ 94.5 Gulfport, JZ 94.5 Pascagoula, JZ 94.5 Ocean Springs, JZ 94.5 Moss Point, JZ 94.5 Gautier, JZ 94.5 Vancleave, JZ 94.5 D'Iberville, JZ 94.5 St. Martin, JZ 94.5 St. Martin, JZ 94.5 Latimer, JZ 94.5 Escatawpa, JZ 94.5 Hurley, JZ 94.5 Wade, JZ 94.5 Saucier, JZ 94.5 Harrison County, JZ 94.5 Jackson County, JZ 94.5 Hancock County, JZ 94.5 Stone County, JZ 94.5 George County, JZ 94.5 Mobile County, JZ 94.5 Alabama, JZ 94.5 New Orleans, JZ 94.5 Louisiana, JZ 94.5 Florida, JZ 94.5 Texas, JZ 94.5 Tennessee, JZ 94.5 Georgia, JZ 94.5 South Carolina, JZ 94.5 North Carolina, JZ 94.5 Virginia, JZ 94.5 Maryland, JZ 94.5 Washington D.C., JZ 94.5 New York, JZ 94.5 New Jersey, JZ 94.5 Pennsylvania, JZ 94.5 Connecticut, JZ 94.5 Rhode Island, JZ 94.5 Massachusetts, JZ 94.5 Vermont, JZ 94.5 New Hampshire, JZ 94.5 Maine, JZ 94.5 Michigan, JZ 94.5 Ohio, JZ 94.5 Indiana, JZ 94.5 Illinois, JZ 94.5 Wisconsin, JZ 94.5 Minnesota, JZ 94.5 Iowa, JZ 94.5 Missouri, JZ 94.5 Arkansas, JZ 94.5 Louisiana " />
                        <meta property="og:description" content={article.excerpt.rendered} />
                        <meta property="og:image" content={article.featured_media_src_url} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="JZ 94.5" />
                    </Helmet>
                    <Palette src={article.featured_media_src_url}>
                        {({ data, loading, error }) => {
                            if (loading) {
                                return <div>Loading...</div>;
                            }

                            if (error) {
                                console.error('Error:', error);
                                return <div>Error occurred while loading image colors.</div>;
                            }

                            return (
                                <>
                                    {/* <div className='article-card' >
                  <img src={article.featured_media_src_url} alt='article' />

                  <div className='article-text'>
                    <h3>{truncateExcerpt(he.decode(article.title.rendered), 52)}</h3>
                    <p dangerouslySetInnerHTML={{
                      __html: truncateExcerpt(article.excerpt.rendered,
                        (width > 1030 ? 150 : 100)
                      ),
                    }}></p>
                  </div>
                </div> */}

                                    <div className='article-post'>
                                        <img className='article-post-img' src={article.featured_media_src_url}></img>
                                        <div className='article-post-text'>
                                            <h1>{he.decode(article.title.rendered)}</h1>
                                            <p dangerouslySetInnerHTML={{
                                                __html: article.content.rendered,
                                            }}></p>

                                        </div>

                                    </div>
                                </>
                            );
                        }}
                    </Palette>
                </>
            )}

            <style>
                {

                    `
            .nav-left > ul {color
            :black}

            .nav-left > img {
              filter: invert(1);
            }

            .social-icons {
              filter: invert(1);
            }

            `

                }
            </style>

        </>
    );
}

export default Article;
