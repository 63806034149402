import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';


const container = document.getElementById('root');
const root = createRoot(container);




root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS theme={{
    colors: {
      brand: ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#044EB3', '#044EB3', '#C50E82', '#AD1374'],
    },
    primaryColor: 'brand',
  }}>
    <Provider store={store}>

      <BrowserRouter>
        <Notifications />
        <App />
      </BrowserRouter>
    </Provider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
