import React, {useRef, useEffect, useState} from 'react';

const Homepage = () => {

  return (
    <>
        <h2>Homepage</h2>
    
    </>
  );

}

export default Homepage;