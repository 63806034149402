import React, { useRef, useEffect, useState } from 'react';
import logo from '../assets/jzlogo.png';
import { Carousel } from '@mantine/carousel';
import he from 'he';
import Autoplay from 'embla-carousel-autoplay';
import topgradient from '../assets/topgradient.png';
import waveformgif from '../assets/waveform.gif';
import ripdanielsimage from '../assets/legacy/newdayfull.png';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import ReactDOM from 'react-dom';
import ArticleView from './articleView';

const LargeArticleView = (props) => {

  const [width, setWidth] = useState(window.innerWidth);

  const [viewMore, setViewMore] = useState(false);

  const [serviceProvider, setServiceProvider] = useState("");
  const [artist, setArtist] = useState("");
  const [artworkUrl, setArtworkUrl] = useState("");
  const [label, setLabel] = useState("");
  const [start, setStart] = useState("");
  const [title, setTitle] = useState("");
  const [trackLength, setTrackLength] = useState("");
  const [trackMixArtist, setTrackMixArtist] = useState("");
  const [trackMixTitle, setTrackMixTitle] = useState("");
  const [trackTitle, setTrackTitle] = useState("");
  const [album, setAlbum] = useState("");
  const [newDayMode, setNewDayMode] = useState(false);
  const objDate = new Date();
  const hours = objDate.getHours();
  const djCheck = () => {
    var a = new Date();
    var d = a.getDay();
    if (d == 1 || d == 2 || d == 3 || d == 4 || d == 5) {

      // 9:00am - 12:00pm
      if (hours == 9 || hours == 10 || hours == 11) {

        setNewDayMode(true);

      }



    }
  }

  const [articleName, setArticleName] = useState("");
  const [articleContent, setArticleContnet] = useState("");
  const [articleImage, setArticleImage] = useState("");




  useEffect(() => {
    djCheck();
    const interval = setInterval(() => {
      djCheck();
    }, 2000);
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    const nowPlayingUrl = 'https://corsproxy.io/?' + encodeURIComponent('https://widgets.autopo.st/fingerprinting/public/jacobybroadnax/nowplaying.json');
    const interval = setInterval(() => {
      fetch(nowPlayingUrl)
        .then(response => response.json())
        .then(data => {
          // Set each state with the corresponding piece of data
          setServiceProvider(data.service_provider);
          setArtist(data.artist);
          setArtworkUrl(data.artwork_url);
          setLabel(data.label);
          setStart(data.start);
          setTitle(data.title);
          setTrackLength(data.track_length);
          setTrackMixArtist(data.track_mix_artist);
          setTrackMixTitle(data.track_mix_title);
          setTrackTitle(data.track_title);
          setAlbum(data.album);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }, 10000);
    return () => clearInterval(interval);


  }, []);

  useEffect(() => {
    const nowPlayingUrl = 'https://corsproxy.io/?' + encodeURIComponent('https://widgets.autopo.st/fingerprinting/public/jacobybroadnax/nowplaying.json');

    fetch(nowPlayingUrl)
      .then(response => response.json())
      .then(data => {
        // Set each state with the corresponding piece of data
        setServiceProvider(data.service_provider);
        setArtist(data.artist);
        setArtworkUrl(data.artwork_url);
        setLabel(data.label);
        setStart(data.start);
        setTitle(data.title);
        setTrackLength(data.track_length);
        setTrackMixArtist(data.track_mix_artist);
        setTrackMixTitle(data.track_mix_title);
        setTrackTitle(data.track_title);
        setAlbum(data.album);
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const autoplay = useRef(Autoplay({
    delay:
      (width > 1030 ? 5000 : 6500)
  }));

  return (
    <>
      <Carousel plugins={[autoplay.current]
      } slideSize="100%" slideGap="sm" controlsOffset="md" controlSize={40} loop withIndicators>
        {/*  {
          width > 1030 ? null : (
            <Carousel.Slide >
              <a target='_blank' rel='noreferrer'>
                {
                  newDayMode ? (
                    <div className='lg-article'>
                      <img src={ripdanielsimage} alt='article' />
                      <div className='lg-article-text vivify fadeIn duration-300'>
                        <h3><img style={
                          {
                            width: '35px',
                          }
                        } src={waveformgif}></img>Now Playing</h3>
                        <h1>{truncateExcerpt(he.decode("It's a New Day"), 52)}</h1>
                        <p dangerouslySetInnerHTML={{
                          __html: truncateExcerpt("With Rip Daniels & Rowe Evans",
                            (width > 1030 ? 150 : 100)
                          )
                        }}></p>
                        <div className='btn-group'></div>
                      </div>
                      <div className='lg-gradient'>
                        <img src={topgradient} alt='gradient' />
                      </div>
                    </div>
                  ) : (
                    <div className='lg-article'>
                      <img src={artworkUrl} alt='article' />
                      <div className='lg-article-text vivify fadeIn duration-300'>
                        <h3><img style={
                          {
                            width: '35px',
                          }
                        } src={waveformgif}></img>Now Playing</h3>
                        <h1>{truncateExcerpt(he.decode(title), 52)}</h1>
                        <p dangerouslySetInnerHTML={{
                          __html: truncateExcerpt(artist,
                            (width > 1030 ? 150 : 100)
                          )
                        }}></p>
                        <div className='btn-group'></div>
                      </div>
                      <div className='lg-gradient'>
                        <img src={topgradient} alt='gradient' />
                      </div>
                    </div>
                  )
                }
              </a>
            </Carousel.Slide>
          )
        } */}

        {props.data.map((article) => (
          <Carousel.Slide key={article.id}>
            <a /* onClick={
              () => {
                setArticleName(article.title.rendered);
                setArticleContnet(article.content.rendered);
                setArticleImage(article.featured_media_src_url);
                setViewMore(true);
              }
            } */ rel='noreferrer' href={
                "/post/" + article.id

              }>
              <div className='lg-article'>

                <img src={article.featured_media_src_url} alt='article' />
                <div className='lg-article-text vivify fadeIn duration-300'>
                  <h3>Featured News</h3>
                  <h1>{truncateExcerpt(he.decode(article.title.rendered), 52)}</h1>
                  <p dangerouslySetInnerHTML={{
                    __html: truncateExcerpt(article.excerpt.rendered,
                      (width > 1030 ? 150 : 100)
                    )
                  }}></p>
                  <div className='btn-group'></div>
                </div>
                <div className='lg-gradient'>
                  <img src={topgradient} alt='gradient' />
                </div>
              </div>
            </a>
          </Carousel.Slide>
        ))}


      </Carousel>

      {ReactDOM.createPortal(
        <div >

          <>
            <SwipeableBottomSheet bodyStyle={{ padding: '18px', borderRadius: '20px 20px 0px 0px', backgroundColor: 'transparent', maxWidth: '800px', margin: 'auto' }} style={{ zIndex: 350 }} overlayStyle={{
              backdropFilter: 'blur(20px)',
            }} overflowHeight={0} open={viewMore} onChange={
              () => {
                setViewMore(false)
              }
            }>
              <div className='modal-screen'
              >
                <div className='modal-pill'>
                  <div className='modal-pill-inner'></div>
                </div>
                <div className='nav-close-container'>
                  <button className='nav-close' onClick={

                    () => setViewMore(false)

                  }>
                    <i className='material-icons'>close</i>
                  </button>
                </div>


                {
                  viewMore ? <div className='modal-content'>
                    <h1 className='modal-title' dangerouslySetInnerHTML={
                      {
                        __html: articleName
                      }

                    }>

                    </h1>
                    <img src={articleImage} alt='article' />
                    <div className='modal-article' dangerouslySetInnerHTML={{
                      __html: articleContent
                    }}>
                    </div>
                    <style>
                      {
                        `
                        body {
                          overflow: hidden;
                        }
                        `
                      }
                    </style>
                  </div> : null
                }
              </div>
            </SwipeableBottomSheet>
          </>

        </div>,
        document.getElementById('modal-root')
      )}
    </>
  );
}

// Function to truncate the excerpt
function truncateExcerpt(excerpt, maxLength) {
  if (excerpt.length > maxLength) {
    return excerpt.slice(0, maxLength) + '...';
  }
  return excerpt;
}

export default LargeArticleView;
