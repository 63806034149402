import React from 'react';
import { Bookmark, Heart, Share} from'tabler-icons-react';
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Badge,
  Group,
  Center,
  Avatar,
  createStyles,
  rem,
} from '@mantine/core';
import he from 'he';


const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: rem(12),
    pointerEvents: 'none',
  },

  title: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: rem(5),
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));

export function ArticleCard(props) {
  const {
    className,
    image,
    link,
    title,
    description,
    author,
    rating,
    ...others
  } = props;

  const { classes, cx, theme } = useStyles();
  const linkProps = { href: link, target: '_blank', rel: 'noopener noreferrer' };

  return (
    <Card withBorder fullWidth radius="md" className={cx(classes.card, className)} {...others}>
      <Card.Section>
        <a {...linkProps}>
         
          <Image src={image} height={180} />
        </a>
      </Card.Section>

      <Text className={classes.title} fw={500} component="a" {...linkProps}>

        {he.decode(title)}
      </Text>

      <Text fz="sm" color="dimmed" dangerouslySetInnerHTML={{ __html: description }} lineClamp={4}>
  
      </Text>

      <Group position="apart" className={classes.footer}>
        <Center>
          <Avatar src={author.image} size={24} radius="xl" mr="xs" />
          <Text fz="sm" inline>
            {author.name}
          </Text>
        </Center>

        <Group spacing={8} mr={0}>
       
          <ActionIcon className={classes.action}>
            <Share size="1rem" />
          </ActionIcon>
        </Group>
      </Group>
    </Card>
  );
}
