import React, { useRef, useEffect, useState } from 'react';


import { Link, useNavigate } from 'react-router-dom';
import LargeArticleView from './largeArticleView';
import { useSelector } from 'react-redux';
import DoubleHeader from './navbar'
import ArticleView from './articleView';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs'
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const LandingPage = () => {
  const newsletter = useSelector(state => state.mainapp.news.newsletter);
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const url = 'https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/posts?categories=7';

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Retrieve the featured media URL for each article
        const articlePromises = data.map((article) => {
          const mediaUrl = `https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/media/${article.featured_media}`;
          return fetch(mediaUrl)
            .then((response) => response.json())
            .then((mediaData) => ({
              ...article,
              featured_media_src_url: mediaData.source_url,
            }));
        });

        // Wait for all the article promises to resolve
        Promise.all(articlePromises).then((articlesWithMedia) => {
          setArticles(articlesWithMedia);
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const [fingerprint, setFingerprint] = useState('');

  useEffect(() => {
    getFingerprint().then((fingerprint) => {
      setFingerprint(fingerprint);
    });
  }
    , []);


  return (
    <>
      {/*    <h1>
        {
          fingerprint
        }
      </h1> */}
      <Helmet>
        <title>JZ 94.5 - The People's Station </title>
        <meta name="description" content={"JZ 94.5 is the People's Station, ."} />

        <meta name="keywords" content="JZ 94.5, WJZD, JZ94.5, JZ 94.5 FM, JZ 94.5 Biloxi, JZ 94.5 Mississippi, JZ 94.5 Gulfport, JZ 94.5 Pascagoula, JZ 94.5 Ocean Springs, JZ 94.5 Moss Point, JZ 94.5 Gautier, JZ 94.5 Vancleave, JZ 94.5 D'Iberville, JZ 94.5 St. Martin, JZ 94.5 St. Martin, JZ 94.5 Latimer, JZ 94.5 Escatawpa, JZ 94.5 Hurley, JZ 94.5 Wade, JZ 94.5 Saucier, JZ 94.5 Harrison County, JZ 94.5 Jackson County, JZ 94.5 Hancock County, JZ 94.5 Stone County, JZ 94.5 George County, JZ 94.5 Mobile County, JZ 94.5 Alabama, JZ 94.5 New Orleans, JZ 94.5 Louisiana, JZ 94.5 Florida, JZ 94.5 Texas, JZ 94.5 Tennessee, JZ 94.5 Georgia, JZ 94.5 South Carolina, JZ 94.5 North Carolina, JZ 94.5 Virginia, JZ 94.5 Maryland, JZ 94.5 Washington D.C., JZ 94.5 New York, JZ 94.5 New Jersey, JZ 94.5 Pennsylvania, JZ 94.5 Connecticut, JZ 94.5 Rhode Island, JZ 94.5 Massachusetts, JZ 94.5 Vermont, JZ 94.5 New Hampshire, JZ 94.5 Maine, JZ 94.5 Michigan, JZ 94.5 Ohio, JZ 94.5 Indiana, JZ 94.5 Illinois, JZ 94.5 Wisconsin, JZ 94.5 Minnesota, JZ 94.5 Iowa, JZ 94.5 Missouri, JZ 94.5 Arkansas, JZ 94.5 Louisiana " />

      </Helmet>

      <DoubleHeader />
      {
        window.location.pathname === '/' ?
          <>
            <LargeArticleView data={articles} />
            <ArticleView data={articles} />
          </>
          :
          null
      }

      <Outlet />


    </>
  );

}

export default LandingPage;