import React from 'react'
import { useState } from 'react';
import { Stepper, Button, Group, TextInput, PasswordInput, Code, NumberInput, Textarea, Switch, Divider, FileInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Mail, Password, User, UserCircle } from 'tabler-icons-react';
import { DatePickerInput } from '@mantine/dates';

const CampaignForm = () => {

    const [active, setActive] = useState(0);

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            phonenumber: '',
            email: '',
            website: '',
            schoolname: '',
            termsAndConditions: true,
            campaignName: '',
            campaignGoal: '',
            campaignDescription: '',
            campaignDeadline: '',
            offerRewards: true,

        },

        /*   validate: (values) => {
            if (active === 0) {
              return {
                username:
                  values.username.trim().length < 6
                    ? 'Username must include at least 6 characters'
                    : null,
                password:
                  values.password.length < 6 ? 'Password must include at least 6 characters' : null,
              };
            }
      
            if (active === 1) {
              return {
                name: values.name.trim().length < 2 ? 'Name must include at least 2 characters' : null,
                email: /^\S+@\S+$/.test(values.email) ? null : 'Invalid email',
              };
            }
      
            return {};
          }, */
    });



    const nextStep = () =>
        setActive((current) => {
            if (form.validate().hasErrors) {
                return current;
            }
            window.scrollTo(0, 0);
            return current < 3 ? current + 1 : current;

        });

    const prevStep = () => {
        window.scrollTo(0, 0);
        setActive((current) => (current > 0 ? current - 1 : current));
    }

    return (
        <>
            <Stepper active={active} size='lg' breakpoint="sm">
                <Stepper.Step icon={<i className='material-icons'>account_circle</i>} label="Signup" description="Create Your Account" >
                    <div className='input-grouper'>
                        <TextInput radius="md"
                            size="lg"
                            mt="lg"
                            label="First Name"
                            placeholder="First Name"
                            {...form.getInputProps('firstName')}
                        />
                        <TextInput radius="md"
                            size="lg"
                            mt="lg"
                            label="Last Name"
                            placeholder="Last Name"
                            {...form.getInputProps('lastName')}
                        />
                    </div>
                    <TextInput radius="md"
                        size="lg" mt="lg" label="School Name" placeholder="School Name Ex: bellhighschool" {...form.getInputProps('schoolname')} />
                    <TextInput radius="md"
                        size="lg" mt="lg" label="Website" placeholder="School Website Link Ex: www.bellhighschool.com" {...form.getInputProps('website')} />
                    <TextInput icon={<UserCircle />} radius="md"
                        size="lg"
                        mt="lg"
                        label="Phone Number"
                        description="Be sure it's a number you can be reached at."
                        placeholder="Phone Number Ex: 555-555-5555"
                        {...form.getInputProps('phonenumber')}
                    />
                    <TextInput icon={<Mail />} description="Please use a school email that ends in the websites domain name." radius="md"
                        size="lg"
                        mt="lg"
                        label="School Email"
                        placeholder="School Email Ex: stacy@bellhighschool.com"
                        {...form.getInputProps('email')}
                    />

                    <PasswordInput icon={<Password />} radius="md"
                        size="lg"
                        mt="lg"
                        label="Password"
                        placeholder="Password"
                        {...form.getInputProps('password')}
                    />
                    <Switch
                        style={{ cursor: 'pointer' }}
                        mt="lg"
                        size='md'
                        checked={form.values.termsAndConditions}
                        onChange={(e) => form.setFieldValue('termsAndConditions', e.currentTarget.checked)}
                        label={<>I agree to the terms and conditions <a href="#" style={{ textDecoration: 'underline' }}>Terms and Conditions</a></>}
                    />
                </Stepper.Step>









                <Stepper.Step icon={<i className='material-icons'>school</i>} label="Campaign Details" description="Details & Marketing">

                    <Divider my="lg" label="Campaign Details" labelPosition="center" />

                    <TextInput radius="md"
                        size="lg" mt="lg" label="Campaign Name" placeholder="Campaign Name Ex: Library Renovation" {...form.getInputProps('campaignName')} />
                    <NumberInput radius="md"
                        size="lg" mt="lg" label="Campaign Goal" placeholder="Fundraising Goal Ex: 50000"
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} formatter={(value) =>
                            !Number.isNaN(parseFloat(value))
                                ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                : '$ '
                        } {...form.getInputProps('campaignGoal')} />
                    <Textarea radius="md"
                        size="lg" mt="lg" autosize minRows={4} maxRows={5} label="Campaign Description" placeholder="Campaign Description" {...form.getInputProps('campaignDescription')} />
                    <DatePickerInput type="range" radius="md"
                        size="lg" mt="lg" label="Expected Campaign Duration" placeholder="Ex: 12/31/2023 - 12/31/2023" {...form.getInputProps('campaignDeadline')} />

                    <Switch
                        style={{ cursor: 'pointer' }}
                        mt="lg"
                        description="Rewards will be offered to students who share the campaign and raise the most money."
                        size='md'
                        checked={form.values.offerRewards}
                        onChange={(e) => form.setFieldValue('offerRewards', e.currentTarget.checked)}
                        label={<>I would like to offer rewards to my students</>}
                    />

                    <Divider my="lg" label="Images And Assets" labelPosition="center" />
                    <FileInput icon={<i className='material-icons'>file_upload</i>} label="Upload School Logo" size='lg' radius="md" mt="lg" placeholder="Upload Image" accept="image/png,image/jpeg" />
                    <FileInput icon={<i className='material-icons'>file_upload</i>} label="Upload Campaign Image" size='lg' radius="md" mt="lg" placeholder="Upload Image" accept="image/png,image/jpeg" />

                </Stepper.Step>













                {/*      <Stepper.Step label="Finalize Details" description="Campaign Overview">
                    <TextInput label="Website" placeholder="Website" {...form.getInputProps('website')} />
                    <TextInput
                        mt="md"
                        label="GitHub"
                        placeholder="GitHub"
                        {...form.getInputProps('github')}
                    />
                </Stepper.Step> */}
                <Stepper.Completed label="Finalize Details" description="Campaign Overview">
                    Completed! Form values:
                    <Code block mt="xl">
                        {JSON.stringify(form.values, null, 2)}
                    </Code>
                </Stepper.Completed>
            </Stepper>

            <Group position="right" mt="xl">
                {active !== 0 && (
                    <Button fullWidth radius="md" size="lg" variant="default" onClick={prevStep} >
                        Back
                    </Button>
                )}
                {active !== 3 && <Button fullWidth radius="md" size="lg" onClick={nextStep}>Next step</Button>}
            </Group>

            <style>
                {`
    nav {
        position:sticky
    }
    `}
            </style>

        </>
    );

}

export default CampaignForm;