import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './css/App.css';
import { getUser } from './httpcalls/authThunk';
import Homepage from './mainapp/homepage';
import MainDirectory from './mainapp/mainDirectory';
import Login from './auth/login';
import LandingPage from './landingPage/landingPage';
import NewCampaign from './campaignForm/new';
import Launch from './mainapp/onboarding/launch';
import './css/vivify.min.css'
import Student from './mainapp/onboarding/student/student';
import Admin from './mainapp/onboarding/admin/admin';
import LoginForm from './mainapp/onboarding/login';
import ScanCode from './mainapp/onboarding/student/s_scanCode';
import SignupForm from './mainapp/onboarding/signup';
import Verification from './mainapp/onboarding/verification';
import ResetPassword from './mainapp/onboarding/resetPassword';
import EnterNewPassword from './mainapp/onboarding/newPassword';
import Article from './landingPage/article';
import LargeArticleView from './landingPage/largeArticleView';
import ArticleView from './landingPage/articleView';
import ViewerPage from './landingPage/viewerPage';
import { useNavigate } from 'react-router-dom';
import posthog from 'posthog-js'
const App = () => {
  const dispatch = useDispatch()
  const [appLoad, setAppLoad] = useState(true)
  const login = useSelector(state => state.auth.userActive)
  const newsletter = useSelector(state => state.mainapp.news.newsletter);
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    posthog.init('phc_d2xxwMpnG2SNbADgmirzc1F3FSRrHaXI0Jodj04S5oE', { api_host: 'https://app.posthog.com' })

  }, [])

  useEffect(() => {
    const url = 'https://corsproxy.io/?' + encodeURIComponent('https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/posts?categories=7');

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Retrieve the featured media URL for each article
        const articlePromises = data.map((article) => {
          const mediaUrl = 'https://corsproxy.io/?' + encodeURIComponent(`https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/media/${article.featured_media}`);
          return fetch(mediaUrl)
            .then((response) => response.json())
            .then((mediaData) => ({
              ...article,
              featured_media_src_url: mediaData.source_url,
            }));
        });

        // Wait for all the article promises to resolve
        Promise.all(articlePromises).then((articlesWithMedia) => {
          setArticles(articlesWithMedia);
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);


  useEffect(() => {
    dispatch(getUser()).then(res => {
      setAppLoad(false)
    })
  }, [])


  return (


    <>
      {(appLoad) ?
        ""
        :
        <Routes>

          {(login) ?
            <Route path='/app' element={<MainDirectory />} >
              <Route path='/app' element={<Homepage />} >

              </Route>
            </Route>
            :
            <>
              <Route path='/' element={<LandingPage />} >
                <Route path='/post/:id' element={<Article />} />

              </Route>
              <Route path='/meet-the-team' element={<ViewerPage category={'8'} />} />
              <Route path='/on-air' element={<ViewerPage category={'9'} />} />
              <Route path='/on-air/' element={<ViewerPage category={'9'} />} />
              <Route path='/new' element={<NewCampaign />} />
              <Route path='/itsanewday' element={
                () => {
                  window.location.href = 'https://itisanewday.com';
                  return null;
                }
              } />



              <Route path='*' element={<Navigate to="/" replace />} />
            </>
          }
        </Routes>
      }
    </>
  );
}

export default App;
