import { createSlice } from "@reduxjs/toolkit";


const initialState = () => ({
    newsletter: [
        {
            id: 1,
            title: 'Rip Daniels Endorses 2023 Candidates',
            excerpt: 'Rip Daniels gives his endorsement to the following candidates for the 2023 election.',
            content: '<p>Rip Daniels gives his endorsement to the following candidates for the 2023 election.</p>',
            imageURl: 'https://images.unsplash.com/photo-1621574539436-4b7b0b5b0b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FuZGlkYXRlcyUyMGZvciUyMHRoZSUyMjAyMyUyMGVsZWN0aW9ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',    
            authorName: 'Rip Daniels',  
        },
        {
            id: 1,
            title: 'Download the JZ 94.5 App',
            excerpt: 'Download the JZ 94.5 App and listen now',
            content: '<p>Download the JZ 94.5 App and listen now</p><a href="https://play.google.com/store/apps/details?id=com.jz945fm.radio" target="_blank" rel="noopener noreferrer">Download the JZ 94.5 App</a>',
            imageURl: 'https://images.unsplash.com/photo-1621574539436-4b7b0b5b0b0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FuZGlkYXRlcyUyMGZvciUyMHRoZSUyMjAyMyUyMGVsZWN0aW9ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',   
            authorName: 'Rip Daniels',   
        },
      

      ]
});

const newsSlice = createSlice({
    name: 'Newsletter',
    initialState: initialState(),
    reducers: {
        resetNewsLetter: state => initialState(),
        alterNewsLetter: (state, action) => {
            state.newsletter = action.payload;

        }
    }
})

export const {
    resetNewsLetter,
    alterNewsLetter


} = newsSlice.actions

export default newsSlice.reducer