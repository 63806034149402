import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import newsSlice from './schemas/newsSlice';


export const store = configureStore({
  reducer: {
    auth:authSlice,
   mainapp: combineReducers({
      news: newsSlice, 
    })
  },
});
