import React, { useEffect, useState } from 'react';
import { ArticleCard } from '../components/articleCard';
import { Carousel } from '@mantine/carousel';
import { Palette } from 'react-palette';
import he from 'he';
import AudioPlayer from '../components/audioPlayer';
import { useNavigate } from 'react-router-dom';
import Navbar from './navbar';

function ViewerPage(props) {
  const [articles, setArticles] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);


  useEffect(() => {
    fetch('https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/posts?categories=' + props.category)
      .then((response) => response.json())
      .then((data) => {
        const articlePromises = data.map((article) =>
          fetch(`https://wjzdinc.jacobybroadnax.com/wp-json/wp/v2/media/${article.featured_media}`)
            .then((response) => response.json())
            .then((mediaData) => ({
              ...article,
              featured_media_src_url: mediaData.source_url,
            }))
        );
        Promise.all(articlePromises).then((articlesWithMedia) => {
          setArticles(articlesWithMedia);
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [props.category]);

  // Function to truncate the excerpt
function truncateExcerpt(excerpt, maxLength) {
  if (excerpt.length > maxLength) {
    return excerpt.slice(0, maxLength) + '...';
  }
  return excerpt;
}
  

  return (
    <>
    <Navbar/>
    <div className='contentItem'>

  
        {articles.map((article) => (
          <Palette src={article.featured_media_src_url}>
            {({ data, loading, error }) => {
              if (loading) {
                return <div>Loading...</div>;
              }

              if (error) {
                console.error('Error:', error);
                return <div>Error occurred while loading image .</div>;
              }

              return (
             <>
                  <div className='article-card vivify fadeIn duration-300' onClick={
                    () => window.open( "/post/" + article.id , (width < 1030 ? '_blank' : '_self'))
                  }>
               
                    <img style={
                      {
                        backgroundColor: 'black'
                      }
                    } src={article.featured_media_src_url} alt='article' />

                    <div className='article-text'>
                      <h3>{truncateExcerpt(he.decode(article.title.rendered), 52)}</h3>
                      <p dangerouslySetInnerHTML={{
                        __html: truncateExcerpt(article.excerpt.rendered,
                          (width > 1030 ? 150 : 100)
                        ),
                      }}></p>
                      </div>
                  </div>
                
             </>
             
    
              );
            }}
          </Palette>
        ))}

<style>
          {
        
            `
            .nav-left > ul {color
            :black}

            .nav-left > img {
              filter: invert(1);
            }

            .social-icons {
              filter: invert(1);
            }

            .contentItem {
                margin-top: 80px;
            }

            .contentItem > div:first-of-type {
                position: static
            }

            `
    
          }
        </style>
    </div>
    </>
  );
}

export default ViewerPage;
